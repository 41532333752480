import
// *
{
    Chart,
    Colors,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
    PieController,
    ArcElement,
    Tooltip
}
    from 'chart.js'
// } from 'chart.js/auto'
Chart.register(
    Colors,
    BarController,
    BarElement,
    CategoryScale,
    LinearScale,
    Legend,
    PieController,
    ArcElement,
    Tooltip
);

// <block:setup:1>
const DATA_COUNT = 5;
const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
const now = new Date();
function getExperienceJSON() {

    const experience = {
        // todo: self database
        labels: [
            'Experience',
            'C#, ASP.NET, EF',
            'HTML, CSS, JS',
            'MSSQL, T-SQL, MySQL',
            'Windows, Active Directory, IIS',
            'Excel, VBA',
            'Leadership, management',
            'Linux, Ubuntu, Raspberry Pi',
            // 'Docker, Virtualization',
            'Azure, AWS, Cloudflare',
            'Networking, hardware, security',
            // 'UI/UX, Creativity'
            'AI / ML / Data science'
        ],
        datasets: [
            {
                // skipNull: true,
                label: 'Total Years',
                data: [
                    now.getFullYear() - 2006 + 1, // 'Years of professional experience',
                    // now.getFullYear() - 2010 + '+ years as a SQL administrator and developer',
                    // now.getFullYear() - 2013 + '+ years of full-stack web development',
                    null, // 'C#, ASP.NET, EF',
                    null, // 'HTML, CSS, JS',
                    null, // 'MSSQL, T-SQL, MySQL',
                    null, // 'Windows, Active Directory, IIS',
                    null, // 'Excel, VBA',
                    null, // 'Leadership, Management',
                    null, // 'Linux, Ubuntu, Raspberry Pi',
                    // null, // 'Docker, Virtualization',
                    null, // 'Azure, AWS, Cloudflare',
                    null, // 'Networking, Hardware, Security',
                    // 0, // 'UI/UX, Creativity'
                    null, // 'AI / ML / Data Science'
                ]
            },
            {
                label: 'DR',
                // backgroundColor: '#3E79BB',
                data: [
                    null, //'Years of professional experience',
                    1, //'C#'
                    null, //'HTML / CSS / JS'
                    1, //'SQL'
                    0, //'Windows'
                    1, //'Excel / VBA'
                    0, //'Leadership Roles'
                    0, //'Linux'
                    // 0, //'Docker'
                    1, //'Azure'
                    0, //'Networking'
                    // 0, //'UI/UX'
                    1, // 'AI / ML / Data Science'
                ]
            },
            {
                label: 'PDA',
                // backgroundColor: '#003764',
                // minBarLength: 200,
                data: [
                    null, //'Years of professional experience',
                    now.getFullYear() - 2015, //'C#'
                    now.getFullYear() - 2015, //'HTML / CSS / JS'
                    now.getFullYear() - 2015, //'SQL'
                    now.getFullYear() - 2015, //'Windows'
                    now.getFullYear() - 2015, //'Excel / VBA'
                    now.getFullYear() - 2015, //'Leadership Roles'
                    now.getFullYear() - 2015, //'Linux'
                    // now.getFullYear() - 2015, //'Docker'
                    now.getFullYear() - 2015, //'Azure'
                    now.getFullYear() - 2015, //'Networking'
                    // now.getFullYear() - 2015, //'UI/UX'
                    now.getFullYear() - 2023, // 'AI / ML / Data Science'
                ],
            },
            {
                label: 'Nerdery',
                // backgroundColor: '#4EABAE',
                data: [
                    null, //'Years of professional experience',
                    2, //'C#'
                    2, //'HTML / CSS / JS'
                    2, //'SQL'
                    0, //'Windows'
                    1, //'Excel / VBA'
                    1, //'Leadership Roles'
                    2, //'Linux'
                    // 0, //'Docker'
                    0, //'Azure'
                    1, //'Networking'
                    // 2, //'UI/UX'
                    null, // 'AI / ML / Data Science'
                ],
            },
            {
                label: 'GC',
                // backgroundColor: '#ff0000',
                data: [
                    null, //'Years of professional experience',
                    0, //'C#'
                    0, //'HTML / CSS / JS'
                    3, //'SQL'
                    3, //'Windows'
                    3, //'Excel / VBA'
                    3, //'Leadership Roles'
                    0, //'Linux'
                    // 0, //'Docker'
                    0, //'Azure'
                    0, //'Networking'
                    // 2, //'UI/UX'
                    1, // 'AI / ML / Data Science'
                ],
            },
            {
                label: 'WnA',
                // backgroundColor: '#ff0000',
                data: [
                    null, //'Years of professional experience',
                    0, //'C#'
                    0, //'HTML / CSS / JS'
                    0, //'SQL'
                    3, //'Windows'
                    0, //'Excel / VBA'
                    3, //'Leadership Roles'
                    0, //'Linux'
                    // 0, //'Docker'
                    0, //'Azure'
                    3, //'Networking'
                    // 0, //'UI/UX'
                    null, // 'AI / ML / Data Science'
                ],
            },
            {
                label: 'IDT',
                // backgroundColor: '#ff0000',
                data: [
                    null, //'Years of professional experience',
                    0, //'C#'
                    0, //'HTML / CSS / JS'
                    1, //'SQL'
                    2, //'Windows'
                    2, //'Excel / VBA'
                    0, //'Leadership Roles'
                    0, //'Linux'
                    // 0, //'Docker'
                    0, //'Azure'
                    2, //'Networking'
                    // 0, //'UI/UX'
                    null, // 'AI / ML / Data Science'
                ],
            },
            {
                label: 'CR',
                // backgroundColor: '#ff0000',
                data: [
                    null, //'Years of professional experience',
                    0, //'C#'
                    0, //'HTML / CSS / JS'
                    0, //'SQL'
                    2, //'Windows'
                    0, //'Excel / VBA'
                    0, //'Leadership Roles'
                    0, //'Linux'
                    // 0, //'Docker'
                    0, //'Azure'
                    2, //'Networking'
                    // 0, //'UI/UX'
                    null, // 'AI / ML / Data Science'
                ],
            }
        ]
    };
    // return JSON.stringify(experience);
    return experience;
}
// </block:config>

const barConfig = {
    type: 'bar',
    options: {
        // layout: {
        //     padding: 20
        // },
        plugins: {
            tooltip: {
                filter: tooltipItem => tooltipItem.dataset.data[tooltipItem.dataIndex] > 0
            },
            legend: {
                display: false,
                position: 'right'
            },
            datalabels: {
                // display: false,
                // formatter: function (value, context) { return value || null;  },
            }
        },
        indexAxis: 'y',
        barThickness: 'flex',
        scales: {
            y: {
                // beginAtZero: false,
                title: {
                    display: false,
                    text: 'Years of experience'
                },
                ticks: {
                    font: {
                        size: 16,
                        family: "'Fira Code', sans-serif"
                    }
                },
                beginAtZero: true,
                stacked: true,
                border: {
                    display: false
                },
                grid: {
                    display: false,
                    drawOnChartArea: true
                }
            },
            x: {
                title: {
                    display: false,
                    font: {
                        size: 16,
                        family: "'Fira Code', sans-serif"
                    },
                    text: 'Years of experience'
                },
                ticks: {
                    font: {
                        size: 16,
                        family: "'Fira Code', sans-serif"
                    }
                },
                stacked: true,
                border: {
                    display: false
                },
                grid: {
                    display: false,
                    drawOnChartArea: true
                }
            }
        }
    },
    data: getExperienceJSON()
}
function barChartOptionsSmall() {

    let chartJSOptionsSmall = JSON.parse(JSON.stringify(barConfig));
    chartJSOptionsSmall.options.indexAxis = 'x';
    chartJSOptionsSmall.options.scales.x.title.display = false;
    chartJSOptionsSmall.options.scales.y.title.display = false;

    chartJSOptionsSmall.options.scales.x.title.font.size = 11;
    chartJSOptionsSmall.options.scales.x.ticks.font.size = 11;
    chartJSOptionsSmall.options.scales.y.ticks.font.size = 11;

    chartJSOptionsSmall.options.scales.x.ticks.maxRotation = 90;
    chartJSOptionsSmall.options.scales.x.ticks.minRotation = 90;

    chartJSOptionsSmall.options.plugins.legend.position = 'bottom';
    chartJSOptionsSmall.options.maintainAspectRatio = false;

    return chartJSOptionsSmall;
}

export const apexCharts = {
    sampleOptions: {
        chart: {
            type: 'line'
        },
        series: [{
            name: 'sales',
            data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
        }],
        xaxis: {
            categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        }
    },
    sampleOptions2: {
        series: [44, 55, 67, 83],
        chart: {
            height: 350,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: '22px',
                    },
                    value: {
                        fontSize: '16px',
                    },
                    total: {
                        show: true,
                        label: 'Professional Experience',
                        formatter: function (w) {
                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                            return 'test'
                        }
                    }
                }
            }
        },
        labels: ['Apples', 'Oranges', 'Bananas', 'Berries'],
    }
}


function buildPieData() {
    var expLabels = [
        'Professional experience',
        'C#, ASP.NET, EF',
        'HTML, CSS, JS',
        'MSSQL, T-SQL, MySQL',
        'Windows, Active Directory, IIS',
        'Excel, VBA',
        'Leadership, Management',
        'Linux, Ubuntu, Raspberry Pi',
        // 'Docker, Virtualization',
        'Azure, AWS, Cloudflare',
        'Networking, Hardware, Security',
        // 'UI/UX, Creativity'
    ]

    var expData =
        [
            {
                skipNull: true,
                // backgroundColor: ['#AAA', '#777'],
                data: [
                    null, // 'Years of professional experience',
                    null, // 'C#, ASP.NET, EF',
                    null, // 'HTML, CSS, JS',
                    null, // 'MSSQL, T-SQL, MySQL',
                    null, // 'Windows, Active Directory, IIS',
                    null, // 'Excel, VBA',
                    null, // 'Leadership, Management',
                    null, // 'Linux, Ubuntu, Raspberry Pi',
                    // null, // 'Docker, Virtualization',
                    null, // 'Azure, AWS, Cloudflare',
                    null, // 'Networking, Hardware, Security',
                    // 0, // 'UI/UX, Creativity'
                ]
            },
            {
                skipNull: true,
                // backgroundColor: ['hsl(0, 100%, 60%)', 'hsl(0, 100%, 35%)'],
                data: [
                    null, // 'Years of professional experience',
                    4, // 'C#, ASP.NET, EF',
                    3, // 'HTML, CSS, JS',
                    3, // 'MSSQL, T-SQL, MySQL',
                    6, // 'Windows, Active Directory, IIS',
                    0, // 'Excel, VBA',
                    0, // 'Leadership, Management',
                    0, // 'Linux, Ubuntu, Raspberry Pi',
                    // 0, // 'Docker, Virtualization',
                    0, // 'Azure, AWS, Cloudflare',
                    0, // 'Networking, Hardware, Security',
                    // 0, // 'UI/UX, Creativity'
                ]
            },
            {
                // backgroundColor: ['hsl(100, 100%, 60%)', 'hsl(100, 100%, 35%)'],
                data: [
                    0,
                    3, //'C#'
                    1, //'HTML / CSS / JS'
                    0, //'SQL'
                    6, //'Windows'
                    0, //'Excel / VBA'
                    0, //'Leadership Roles'
                    0, //'Linux'
                    // 0, //'Docker'
                    0, //'Azure'
                    0, //'Networking'
                    // 0, //'UI/UX'
                ]
            },
            // {
            //     backgroundColor: ['hsl(180, 100%, 60%)', 'hsl(180, 100%, 35%)'],
            //     data: [10, 90]
            // }
        ]

    return {
        labels: expLabels,
        datasets: expData
    };
}
// </block:setup>

// <block:config:0>
const pieConfig = {
    type: 'pie',
    data: buildPieData(),
    options: {
        scales: {},
        responsive: true,
        plugins: {
            legend: {
                display: false,
                labels: {
                    generateLabels: function (chart) {
                        // Get the default label list
                        const original = Chart.overrides.pie.plugins.legend.labels.generateLabels;
                        const labelsOriginal = original.call(this, chart);

                        // Build an array of colors used in the datasets of the chart
                        // let datasetColors = chart.data.datasets.map(function (e) {
                        //     return e.backgroundColor;
                        // });
                        // datasetColors = datasetColors.flat();

                        // Modify the color and hide state of each label
                        labelsOriginal.forEach(label => {
                            // There are twice as many labels as there are datasets. This converts the label index into the corresponding dataset index
                            // label.datasetIndex = (label.index - label.index % 2) / 2;

                            // The hidden state must match the dataset's hidden state
                            label.hidden = !chart.isDatasetVisible(label.datasetIndex);

                            // Change the color to match the dataset
                            // label.fillStyle = datasetColors[label.index];
                        });

                        return labelsOriginal;
                    }
                },
                onClick: function (mouseEvent, legendItem, legend) {
                    // toggle the visibility of the dataset from what it currently is
                    legend.chart.getDatasetMeta(
                        legendItem.datasetIndex
                    ).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
                    legend.chart.update();
                }
            },
            datalabels: {
                formatter: function (value, context) { return value || null; },
            },
            tooltip: {
                callbacks: {
                    // label: function (context) {
                    //     return context.formattedValue + ' years';
                    // }
                }
            }
        }
    },
};

export const chartJS = {
    barChartOptions: barConfig,
    barChartOptionsSmall: barChartOptionsSmall(),
    multiSeriesPieChartOptions: pieConfig
};

const yaynaydata = {
    labels: ['Overall Yay', 'Overall Nay', 'Group A Yay', 'Group A Nay', 'Group B Yay', 'Group B Nay', 'Group C Yay', 'Group C Nay'],
    datasets: [
        {
            backgroundColor: ['#AAA', '#777'],
            data: [21, 79]
        },
        {
            backgroundColor: ['hsl(0, 100%, 60%)', 'hsl(0, 100%, 35%)'],
            data: [28, 67]
        },
        {
            backgroundColor: ['hsl(100, 100%, 60%)', 'hsl(100, 100%, 35%)'],
            data: [20, 80]
        },
        {
            backgroundColor: ['hsl(180, 100%, 60%)', 'hsl(180, 100%, 35%)'],
            data: [10, 90]
        }
    ]
};